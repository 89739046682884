import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { SettingsInfo } from './SettingsInfo'
import { customFonts, FontPicker, IonIcon } from '@valuecase/ui-components'
import { useCallback, useEffect, useState } from 'react'
import { useNotifications } from '@/utils/Notifications/Notifications'
import { buttonVariants } from '@valuecase/ui-components/src/components/ui/button'
import {
  generatePageNameForMixpanelBasedOnOurRoutes,
  useTrackEvent,
} from '@/mixpanel/useTrackEvent'
import { useCurrentLocation } from '../navigation/hooks/useCurrentLocation'

export const FontSetting = () => {
  const { success } = useNotifications()
  const { companySettings, updateCompanySettings } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Font style updated successfully')
    },
  })
  const { trackEvent } = useTrackEvent()
  const currentLocation = useCurrentLocation()

  const [selectedFontFamily, setSelectedFontFamily] = useState<(typeof customFonts)[number] | null>(
    null,
  )

  useEffect(() => {
    if (companySettings) {
      setSelectedFontFamily(companySettings?.fontFamily || null)
    }
  }, [companySettings, companySettings?.fontFamily, selectedFontFamily])

  const handleFontChange = useCallback(
    async (
      newValue:
        | {
            fontFamily: null
          }
        | {
            fontFamily: (typeof customFonts)[number]
            fontFamilySpec: string
          },
    ) => {
      if (newValue.fontFamily !== null) {
        updateCompanySettings({
          fontFamily: newValue.fontFamily,
          fontFamilySpec: newValue.fontFamilySpec,
        })
      } else {
        updateCompanySettings({
          fontFamily: null,
        })
      }
      trackEvent({
        event: 'font-changed',
        eventProperties: {
          fontFamily: newValue.fontFamily ?? null,
          indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
        },
      })
    },
    [trackEvent, currentLocation, updateCompanySettings],
  )

  return (
    <div className='flex justify-between w-full items-start'>
      <div className={'flex flex-col gap-2'}>
        <SettingsInfo
          title='Font Style'
          description='Select the typeface that will be used in your spaces. '
        />
        <div>
          <a
            className={buttonVariants({
              variant: 'outlined',
              size: 'small',
            })}
            href="mailto:niclas@valuecase.de?subject=Request%20for%20a%20custom%20font&body=Hi%20Niclas%2C%20%0A%0AI'd%20like%20to%20request%20the%20%5Bcustom%20font%20name%5D%20for%20our%20Valuecase%20account%2C%20please."
          >
            <div className={'flex items-center gap-1'}>
              <IonIcon className={'w-3 h-3'} name='sparkles-outline' />
              Request custom font
            </div>
          </a>
        </div>
      </div>

      <div className='relative flex flex-col gap-2 items-end'>
        <FontPicker
          className={'w-44'}
          value={selectedFontFamily}
          onValueChange={handleFontChange}
        />
      </div>
    </div>
  )
}
