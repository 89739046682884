import { Button2 } from '@valuecase/ui-components/src/components/ui/button'

import {
  AddAssetDialog,
  AssetTable,
  FileHelper,
  TAssetDialogResult,
  useAssetLibraryFolder,
  useMutateAssetLibraryEntry,
  useNotifications,
  AddNewFolderDialog,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useS3Upload } from '@/api/s3'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import { useGenerateLinkThumbnail } from '@/modules/assetLibrary/hooks/useGenerateLinkThumbnail'
import { TAssetLibraryEntry } from '@valuecase/common'
import { useNavigate } from '@tanstack/react-router'
import { useHasPermission } from '@/auth/permissions'

export const AssetLibrary = () => {
  const navigateTo = useNavigate()
  const { tenant } = useCurrentTenantQuery()
  const { success, error } = useNotifications()

  const canManageLibrary = useHasPermission('ASSET_LIBRARY_MANAGE')

  const addAssetQueryParamSet = useMemo(
    () => new URLSearchParams(window.location.search).get('addAsset') === 'true',
    [],
  )

  const queryFolderId = useMemo(() => {
    return new URLSearchParams(window.location.search).get('folderId')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  const { data: folder, isLoading: folderIsLoading } = useAssetLibraryFolder(
    queryFolderId ?? undefined,
  )

  const noQueryFolderIdOrFolderLoaded = useMemo(
    () => queryFolderId === null || !folderIsLoading,
    [folderIsLoading, queryFolderId],
  )

  const validFolderIdOrNull = useMemo(
    // query folder id could be invalid (e.g. not found, deleted...)
    () => (!noQueryFolderIdOrFolderLoaded ? null : folder?.id ?? null),
    [folder, noQueryFolderIdOrFolderLoaded],
  )

  useEffect(() => {
    if (queryFolderId && noQueryFolderIdOrFolderLoaded && !folder) {
      // no valid folder found
      error('Folder not found')
      navigateTo({ to: '/assetLibrary' })
    }
  }, [folder, noQueryFolderIdOrFolderLoaded, navigateTo, queryFolderId, error])

  const [addAssetDialogOpen, setAddAssetDialogOpen] = useState(addAssetQueryParamSet)

  const { generateLinkThumbnail } = useGenerateLinkThumbnail()
  const { upload } = useS3Upload()

  const { createAsset } = useMutateAssetLibraryEntry()
  const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false)

  const uploadAsset = useCallback(
    async (result: TAssetDialogResult<true>) => {
      try {
        switch (result.type) {
          case 'LINK':
            await createAsset({
              folderId: validFolderIdOrNull,
              asset: {
                type: 'LINK',
                data: {
                  linkName: result.name,
                  url: result.url.includes('://') ? result.url : `https://${result.url}`,
                  thumbnail_s3ObjectKey: result.thumbnail?.thumbnail_s3ObjectKey,
                },
              },
              name: result.name,
              description: result.description,
            })
            break
          case 'EMBED':
            await createAsset({
              folderId: validFolderIdOrNull,
              asset: {
                type: 'EMBED',
                data: {
                  ...result,
                  hidden: false,
                },
              },
              name: result.name,
              description: result.description,
            })
            break
          case 'EMBEDDABLE_LINK':
            await createAsset({
              folderId: validFolderIdOrNull,
              asset: {
                type: 'EMBEDDABLE_LINK',
                data: {
                  url: result.url,
                  embedType: result.embedType,
                  name: result.name,
                  thumbnail_s3ObjectKey: result.thumbnail?.thumbnail_s3ObjectKey,
                  hidden: false,
                },
              },
              name: result.name,
              description: result.description,
            })
            break
          case 'FILE': {
            const { s3ObjectKey } = await upload(result.file)
            let thumbnail_s3ObjectKey: undefined | string

            if (result.thumbnail) {
              thumbnail_s3ObjectKey = (await upload(result.thumbnail)).s3ObjectKey
            }

            const assetFileName = FileHelper.maybeRewriteFilenameWithCustomName(
              result.file.name,
              result.customFileName,
            )

            await createAsset({
              folderId: validFolderIdOrNull,
              asset: {
                type: 'FILE',
                data: {
                  s3ObjectKey,
                  thumbnail_s3ObjectKey,
                  fileName: assetFileName,
                  mimeType: result.file.type,
                  hidden: false,
                },
              },
              name: assetFileName,
              description: result.description,
            })
            break
          }
        }
        success('Asset uploaded successfully')
      } catch (e) {
        console.error(e)
        error('Failed to upload asset')
      }
    },
    [success, createAsset, validFolderIdOrNull, upload, error],
  )

  const assetUrl = useCallback((asset: TAssetLibraryEntry['asset']) => {
    switch (asset.type) {
      case 'LINK':
      case 'EMBEDDABLE_LINK':
        return asset.data.url
      case 'FILE':
        return asset.data.s3ObjectUrl
      default:
        return null
    }
  }, [])

  const openAsset = useCallback(
    ({ asset }: TAssetLibraryEntry) => {
      const url = assetUrl(asset)

      if (!url) {
        return
      }

      window.open(url, '_blank')
    },
    [assetUrl],
  )

  return (
    <div className='flex flex-col py-11 w-full'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-118'}>
          <h1 className={'text-2xl font-bold'}>Asset Library</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Upload assets to make them accessible to everyone in your company for use in their
            templates and spaces.
          </p>
        </div>
        {canManageLibrary && (
          <div className={'flex items-center gap-2'}>
            <Button2
              variant={'subtle'}
              leadingIcon={'folder-open'}
              onClick={() => setAddFolderDialogOpen(true)}
            >
              Add Folder
            </Button2>
            <Button2
              leadingIcon='cloud-upload'
              onClick={() => {
                setAddAssetDialogOpen(true)
              }}
            >
              Add Asset
            </Button2>
          </div>
        )}
      </div>
      <div className='w-full border-grey-s2 border-t-[1px] mt-10' />
      {noQueryFolderIdOrFolderLoaded && (
        <AssetTable
          showActions={canManageLibrary}
          onAddAsset={() => setAddAssetDialogOpen(true)}
          onAssetClick={openAsset}
          locale={tenant?.payload.locale}
          className={'mt-4 pb-8 flex-grow'}
          disableClickOnEmbeds
          parentFolderId={validFolderIdOrNull}
          onParentFolderIdChange={(folderId) => {
            if (folderId === null) {
              navigateTo({ to: '/assetLibrary' })
              return
            }
            navigateTo({ to: `/assetLibrary`, search: { folderId } })
          }}
          addAssetAllowed={canManageLibrary}
        />
      )}
      <AddAssetDialog
        showDescription
        open={addAssetDialogOpen}
        onOpenChange={setAddAssetDialogOpen}
        title={'Add asset'}
        subTitle={'Upload embeds or files to your asset library.'}
        uploadButtonText={'Upload'}
        embedOrUploadCallback={uploadAsset}
        embedVariant={'embedAndLink'}
        requireNameForEmbed={true}
        generateLinkThumbnail={generateLinkThumbnail}
      />
      <AddNewFolderDialog
        parentFolderId={validFolderIdOrNull}
        open={addFolderDialogOpen}
        onOpenChange={setAddFolderDialogOpen}
      />
    </div>
  )
}
