import * as AccordionPrimitive from '@radix-ui/react-accordion'

import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cn } from '../../lib'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'
import { Button2 } from './button'
import { IonIcon } from '../../Icons/IonIcon'

const Accordion = AccordionPrimitive.Root

const AccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionClickableTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    disabledTooltip?: string
    onClick: () => void
  }
>(({ className, children, disabledTooltip, onClick, disabled, ...props }, ref) => (
  <AccordionPrimitive.Header>
    <div
      role={'button'}
      className={cn(
        'flex items-center gap-2 p-2 font-medium text-sm w-full focus:hidden',
        className,
      )}
      onClick={onClick}
    >
      <div className={'flex items-center gap-2 w-full'}>
        <AccordionPrimitive.Trigger
          ref={ref}
          className={cn('flex transition-all [&[data-state=open]_.ion-icon]:rotate-90', className)}
          onClick={(e) => {
            if (!onClick) {
              return
            }
            e.stopPropagation()
          }}
          disabled={disabled}
          {...props}
        >
          <Tooltip open={disabled && disabledTooltip ? undefined : false} delayDuration={500}>
            <TooltipTrigger>
              <Button2
                leadingIcon={'chevron-forward'}
                variant={'plain'}
                size={'x-small'}
                disabled={disabled}
              />
            </TooltipTrigger>
            <TooltipContent>{disabledTooltip}</TooltipContent>
          </Tooltip>
        </AccordionPrimitive.Trigger>
        {children}
      </div>
    </div>
  </AccordionPrimitive.Header>
))
AccordionClickableTrigger.displayName = 'AccordionClickableTrigger'

const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className={'flex'}>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex rounded-lg gap-2 p-4 pl-6 hover:bg-[#E9EDF7] transition-colors text-left flex-1',
        '[&[data-state=open]_.closed-icon]:hidden [&[data-state=closed]_.opened-icon]:hidden',
        '[&[data-state=open]]:bg-[#E9EDF7] group',
        className,
      )}
      {...props}
    >
      <IonIcon
        name={'add-circle-outline'}
        className={'closed-icon w-5 h-5 text-grey-s4 group-hover:text-primary-s5 transition-colors'}
      />
      <IonIcon name={'add-circle'} className={'opened-icon w-5 h-5 text-primary-s5'} />
      <span className={'text-sm font-medium text-grey-s6'}>{children}</span>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className='overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'
    {...props}
  >
    <div className={cn('pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionClickableTrigger, AccordionContent }
