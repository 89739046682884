import { FC, useMemo, useState } from 'react'
import { TemplateCreatorLibraryItemsData } from '@/modules/templates/types/TemplateCreatorLibraryItemsData'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  cn,
  IonIcon,
  AccordionTrigger,
  OneLineTruncatedText,
  Divider,
} from '@valuecase/ui-components'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'

interface TemplateCreatorTemplateListProps {
  tenantTemplates: TemplateCreatorLibraryItemsData
  publicTemplates: TemplateCreatorLibraryItemsData
  selectTemplate: (templateId: string) => void
  selectedTemplateId?: string
}

export const TemplateCreatorLibrary: FC<TemplateCreatorTemplateListProps> = ({
  publicTemplates,
  tenantTemplates,
  selectTemplate,
  selectedTemplateId,
}) => {
  const { tenant } = useCurrentTenantQuery()

  const groupedTemplates = useMemo(() => {
    const grouped: {
      group: string | 'Other'
      templates: TemplateCreatorLibraryItemsData
    }[] = []

    for (const template of publicTemplates) {
      const group = grouped.find(
        (group) => group.group === (template.publicLibraryCategory ?? 'Other'),
      )

      if (group) {
        group.templates.push(template)
      } else {
        grouped.push({
          group: template.publicLibraryCategory ?? 'Other',
          templates: [template],
        })
      }
    }

    grouped.sort((a, b) => {
      if (a.group === 'Other') {
        // Move "Other" to the end
        return 1
      }
      if (b.group === 'Other') {
        // Keep "Other" at the end
        return -1
      }
      return a.group.localeCompare(b.group)
    })

    return tenantTemplates.length > 0
      ? [
          {
            group: `${tenant?.payload?.tenantName ?? 'Tenant'} Templates`,
            templates: tenantTemplates.filter((t) => !t.public),
          },
          ...grouped,
        ]
      : grouped
  }, [publicTemplates, tenant?.payload?.tenantName, tenantTemplates])

  const [selectedGroup, setSelectedGroup] = useState<string>('')

  return (
    <div className={'p-3 flex flex-col gap-2'}>
      <button
        className={cn(
          'flex rounded-lg gap-2 p-4 pl-6 hover:bg-[#E9EDF7] transition-colors text-left group',
          {
            'bg-[#E9EDF7]': selectedTemplateId === '',
          },
        )}
        onClick={() => {
          selectTemplate('')
          setSelectedGroup('')
        }}
      >
        <IonIcon
          name={selectedTemplateId === '' ? 'add-circle' : 'add-circle-outline'}
          className={cn('w-5 h-5 text-primary-s5 group-hover:text-primary-s5 transition-colors', {
            'text-grey-s4': selectedTemplateId !== '',
          })}
        />
        <span className={'text-sm font-medium'}>Start from blank</span>
      </button>

      <Divider text={'Template Library'} />

      <Accordion
        type={'single'}
        className={'flex flex-col gap-2'}
        collapsible
        value={selectedGroup}
        onValueChange={setSelectedGroup}
      >
        {groupedTemplates.map(({ group, templates }) => (
          <AccordionItem value={group} key={group}>
            <AccordionTrigger
              className={'text-grey-s4 font-semibold text-xs'}
              onClick={() => {
                selectTemplate(templates[0].id)
              }}
            >
              {group}
            </AccordionTrigger>
            <AccordionContent
              className={'flex flex-col gap-5 mt-5 mb-3 mx-8 border-l-2 border-grey-s2'}
            >
              {templates.map((t) => (
                <TemplateAccordionContentItem
                  key={t.id}
                  template={t}
                  selectTemplate={selectTemplate}
                  selected={selectedTemplateId === t.id}
                />
              ))}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

const TemplateAccordionContentItem = ({
  template,
  selected,
  selectTemplate,
}: {
  template: { id: string; title: string; emojiIcon?: string }
  selected: boolean
  selectTemplate: (templateId: string) => void
}) => {
  return (
    <button className={cn()} key={template.id} onClick={() => selectTemplate(template.id)}>
      <div
        className={cn(
          'flex items-center gap-1 pl-4 font-medium text-grey-s4 text-sm',
          'hover:text-primary-s4 transition-colors',
          {
            '-ml-0.5 border-l-2 border-primary-s5 text-primary-s5 hover:text-primary-s5': selected,
          },
        )}
      >
        <span>{template.emojiIcon ?? '🚀'}</span>
        <OneLineTruncatedText>{template.title}</OneLineTruncatedText>
      </div>
    </button>
  )
}
