export const Divider = ({ text }: { text?: string }) => {
  return (
    <div className='flex items-center gap-2'>
      <div className='flex-grow h-[1px] bg-grey-s2'></div>
      {!!text && (
        <>
          <div className='text-grey-s4 font-semibold text-xs uppercase'>{text}</div>
          <div className='flex-grow h-[1px] bg-grey-s2'></div>
        </>
      )}
    </div>
  )
}
