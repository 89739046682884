import { PopoverContentProps } from '@radix-ui/react-popover'
import { getCompanyInitials, IWhitelistedUser } from '@valuecase/common'
import { IonIcon } from '../../Icons/IonIcon'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AvatarThumbnail,
  Command,
  CommandCheckboxItem,
  CommandEmpty,
  CommandInput,
  CommandList,
  Divider,
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
  PopoverContent,
  PopoverTrigger,
  UserAvatarProps,
} from '../../components/ui'
import { Button2 } from '../../components/ui/button'

const notificationLabels = {
  allComments: 'comments',
  dueToday: 'reminders',
  milestoneDone: 'milestone done',
  taskAssigned: 'task assigned',
  unsubscribed: 'unsubscribed',
}

const getNotificationLabelComponent = (
  settings: IWhitelistedUser['WhitelistedUserNotificationsSettings'],
) => {
  const allNotificationsOnLabel = (
    <div className='flex items-center gap-[2px] text-grey-s5 text-xs'>
      <IonIcon name='notifications-outline' />
      All notifications on
    </div>
  )

  const noNotificationsLabel = (
    <div className='flex items-center gap-[2px] text-xs text-grey-s4'>
      <IonIcon name='notifications-off-outline' />
      All notifications off
    </div>
  )

  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  if (!settings) {
    // No notification settings on user - show no notifications label
    return noNotificationsLabel
  } else {
    const { id, whitelistedUserId, ...notifications } = settings

    const activeNotifications = Object.keys(notifications)
      .filter((key) => notifications[key as keyof typeof notifications] === true)
      .map((key) => notificationLabels[key as keyof typeof notificationLabels])

    if (activeNotifications.length === 0) {
      // No active notifications - show no notifications label
      return noNotificationsLabel
    } else if (activeNotifications.length === Object.keys(notifications).length) {
      // All notifications are active - show all notifications on label
      return allNotificationsOnLabel
    } else {
      // Some notifications are active - show them

      return (
        <div className='flex items-center gap-[2px] text-grey-s5 text-xs truncate'>
          <IonIcon name='notifications-outline' />
          {capitalize(activeNotifications[0]) +
            (activeNotifications.length > 1
              ? `, ${activeNotifications.slice(1).join(', ')}`
              : '')}{' '}
        </div>
      )
    }
  }
}

export function AddUserPopoverContent({
  children,
  align,
}: {
  children: ReactNode
  align: PopoverContentProps['align']
}) {
  return (
    <PopoverContent
      align={align}
      sideOffset={8}
      hideClose
      className={'w-full p-3 max-w-[var(--radix-popover-content-available-width)]'}
    >
      {children}
    </PopoverContent>
  )
}

export function AddUserPopoverTrigger() {
  return (
    <PopoverTrigger asChild>
      <Button2
        leadingIcon={'person-outline'}
        trailingIcon={'add'}
        variant={'subtle'}
        size={'small'}
      />
    </PopoverTrigger>
  )
}

export function AddUserPopoverSearchableUserList({ children, ...props }: SearchableUserListProps) {
  return (
    <SearchableUserList
      className={'min-h-[150px] max-h-[calc(var(--radix-popover-content-available-height)-160px)]'}
      {...props}
    >
      {children}
    </SearchableUserList>
  )
}

type SearchableUserListProps = {
  search?: string
  onSearchChange?: (search: string) => void
  userCount: number
  children: ReactNode
  className?: string
  searchPlaceholder?: string
}

export function SearchableUserList({
  search,
  onSearchChange,
  userCount,
  children,
  className,
  searchPlaceholder,
}: SearchableUserListProps) {
  return (
    <Command>
      {userCount > 5 && (
        <CommandInput
          value={search}
          onValueChange={onSearchChange}
          placeholder={searchPlaceholder ?? 'Search...'}
        />
      )}
      <CommandList className={className}>
        <CommandEmpty>
          <EmptyState icon={'telescope'} iconSize={'small'} className={'my-4'}>
            <EmptyStateHeader>
              <EmptyStateTitle>Nothing found!</EmptyStateTitle>
              <EmptyStateDescription>
                You may want to check for typos, try another name
              </EmptyStateDescription>
            </EmptyStateHeader>
          </EmptyState>
        </CommandEmpty>

        {children}
      </CommandList>
    </Command>
  )
}

export function SearchableUserItem({
  selected,
  onSelect,
  onAddEmail,
  user: { name, email, photoUrl, avatarVariant, unsubscribed, notificationSettings },
  disabled,
  showNotificationLabel,
}: {
  selected: boolean
  onSelect: () => void
  onAddEmail?: () => void
  user: {
    name?: string
    email?: string
    photoUrl?: string
    avatarVariant: UserAvatarProps['variant']
    unsubscribed?: boolean
    notificationSettings?: IWhitelistedUser['WhitelistedUserNotificationsSettings']
  }
  disabled?: boolean
  showNotificationLabel?: boolean
}) {
  const { t } = useTranslation()

  return (
    <CommandCheckboxItem
      disabled={disabled}
      className='mt-1'
      selected={selected}
      onSelect={onSelect}
    >
      <div className={'flex justify-between items-center w-full'}>
        <div className={'flex items-center gap-2'}>
          {photoUrl ? (
            <AvatarThumbnail shape={'CIRCLE'} type={'image'} imageUrl={photoUrl} size={'SMALL'} />
          ) : (
            <AvatarThumbnail
              shape={'CIRCLE'}
              type={'user'}
              initials={getCompanyInitials(name ?? email ?? '')}
              variant={avatarVariant}
              size={'SMALL'}
            />
          )}

          <div className={'flex flex-col gap-1 text-sm'}>
            <span className={'font-medium'}>{name ?? email ?? ''}</span>

            {showNotificationLabel && !!email ? (
              getNotificationLabelComponent(notificationSettings)
            ) : (
              <div className={'flex gap-1 text-xs items-center align-middle'}>
                {!!email && <span className={'text-grey-s5'}>{email}</span>}
                {!email && onAddEmail && !unsubscribed && (
                  <Button2
                    size={'link'}
                    variant={'link'}
                    leadingIcon={'add'}
                    onClick={(e) => {
                      e.stopPropagation()
                      onAddEmail?.()
                    }}
                  >
                    {t('map.detailsModal.metadata.assignee.userList.searchPopup.addEmailButton')}
                  </Button2>
                )}
                {unsubscribed && <span className={'text-warning-s3 font-bold'}>Unsubscribed</span>}
              </div>
            )}
          </div>
        </div>
      </div>
    </CommandCheckboxItem>
  )
}

export function UserPopoverActions({ children }: { children: ReactNode }) {
  return (
    <div className={'flex flex-col gap-2 pt-3 '}>
      <Divider />
      <div className={'flex w-full gap-2 pt-2'}>{children}</div>
    </div>
  )
}
